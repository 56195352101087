import React from "react";
import DealersDesktop from "./DealersDesktop";
import DealersMobile from "./DealersMobile";



const DealersBody = ({isDesktop, dealers, isShow}) => {


if (isDesktop) {

    return (
       <DealersDesktop dealers={dealers} />
    )
}

else {
    return (
   
        <DealersMobile dealers={dealers} isShow={isShow}/>
   
    )
}

}

export default DealersBody