import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const DealersMobile = ({dealers, isShow}) => {



        return (
       
           
            
            <div style={{ marginTop: 0 }} 
            className={isShow ?"dealers__body mobile" :"dealers__body mobile_isShow"}>
 
            <div className="stickyBlock">
              {dealers.map((dealer, i) => {

                const setImg = getImage(
                  dealer.map_image.localFile.childImageSharp
                );
        
                return (
                  <div
                    className={`dealers__block ${i == 1 ? "mt-30" : ""}`}
                    key={dealer.strapi_id}
                  >
                    <div className="dealers__header">
                      <h1>{dealer.city}</h1>
                    </div>
                    <div className="dealers__map">
                      {
                        <GatsbyImage
                          placeholder="#fff"
                          image={setImg}
                          alt={dealer.city}
                        />
                      }
                    </div>
                    <div className="dealers__adres mt-30 mb-30">
                        
                      <h2>{dealer.company_name}</h2>
                      <ul>
                        <li>
                          <span>Адрес:</span> {dealer.business_address}
                        </li>
                        <li>
                          <span>Тел.:</span> {dealer.phone}
                        </li>
                        <li>
                          <span>E-mail:</span> {dealer.email}
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
         
          
        )


}

export default DealersMobile;