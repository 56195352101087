import React, { useState } from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";

import DealersBody from "../components/Dealers/Dealers";
import { useMediaQuery } from 'react-responsive';
import SvgIcons from "../components/svgIcons/svgIcons";


const Dealers = () => {
  const data = useStaticQuery(graphql`
    query DealersQuery {
      allStrapiDealer(sort: { fields: type_dealers }) {
        group(field: type_dealers) {
          group(field: city) {
            nodes {
              business_address
              city
              company_name
              email
              phone
              id
              strapi_id
              type_dealers
              map_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const allDealersfromStrapi = data.allStrapiDealer.group;

  const [dealers, setDealers] = useState([
    {
      city: "Санкт-Петербург",
      company_name: "АО «ЭНЕРГИЯ»",
      business_address: "Россия, г. Санкт-Петербург, д. Глядино",
      phone: "+7 (812) 640-30-30 (многоканальный)",
      email: "office@dendor.ru",
      strapi_id: 2,
      type_dealers: 1,
      map_image: allDealersfromStrapi[0].group[1].nodes[0].map_image,
    },
  ]);

  let [isShow, setShow] = useState(false);



  function selectDealers(dealers) {
    setDealers(dealers);
    setShow(true);

  }

  function closeDealers () {
    setShow(false);

}

const isDesktop = useMediaQuery({ query: '(min-width: 577px)' })

  function isDealer(id) {
    id = id + 1;
    let type_dealer;
    switch (id) {
      case 1:
        type_dealer = "ЭКСКЛЮЗИВНЫЙ ДИСТРИБЬЮТОР";
        break;
      case 2:
        type_dealer = "ДИЛЕРЫ:";
        break;
      case 3:
        type_dealer = "ОФИЦИАЛЬНЫЕ ПРЕДСТАВИТЕЛИ:";
        break;
    }
    return type_dealer;
  }

  return (
    <Layout>
      <div className="container mt-60">
        <h1 className="text-center"></h1>
        <div className="dealer_flex">
          <div className="dealers">
            <div className="dealers__sidebar">
              <ul>
                {allDealersfromStrapi.map((groupsBytypeDealers, idDealer) => {
                  return (
                    <span key={isDealer(idDealer)}>
                      <li className="dealers__type">{isDealer(idDealer)}</li>
                      {groupsBytypeDealers.group.map((dealersByCity) => {
                        const { city, strapi_id } = dealersByCity.nodes[0];
                        if (isDesktop) {

                          return (
                            <li
                              onMouseEnter={() =>
                                selectDealers(dealersByCity.nodes)
                              }
                              key={strapi_id}
                            >
                              {city}
                            </li>
                          );
                        }
                        else {
                          return (
                            <li
                              onClick={() =>
                                selectDealers(dealersByCity.nodes)
                              }
                              key={strapi_id}
                            >
                              {city}
                            </li>
                          );
                        }
                      })}
                    </span>
                  );
                })}
              </ul>
            </div>
         {isShow &&   (!isDesktop && <div className="mobileCloseVacancies">
          <div className="logo">
                <a href="/">
                  <SvgIcons width="200" height="35" name="dendor" />
                </a>
              </div>
          <div className="btnCloseVacancy">    <span onClick={()=> closeDealers()} className="">X</span></div>
              </div> ) }
           <DealersBody isDesktop={isDesktop} dealers={dealers} isShow={isShow}/>
           
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dealers;
